import { forwardRef } from "react";
import { Menu, MenuItemProps } from "@headlessui/react";
import joinClassNames from "classnames";

import classes from "./styles/classes.module.scss";

interface ItemComponent {
  (props: MenuItemProps<"li">): JSX.Element;
}

const Item = forwardRef<HTMLLIElement, MenuItemProps<never>>(
  ({ children, className, ...restProps }, ref) => (
    <Menu.Item
      {...restProps}
      ref={ref}
      as="li"
      className={joinClassNames(classes.item, className)}
    >
      {children}
    </Menu.Item>
  ),
);

export default Item as ItemComponent;
