import useSelector from "hooks/redux/use-selector";
import useDispatch from "hooks/redux/use-dispatch";

import { accountSelectors, accountThunks } from "store/account";

export const useConnect = () => {
  const isLoading = useSelector(state => state.account.isLoadingPaymentMethods);
  const isCreatingPaymentMethod = useSelector(
    state => state.account.isCreatingPaymentMethod,
  );
  const error = useSelector(state => state.account.paymentMethodsError);
  const paymentMethodIds = useSelector(
    state => state.account.paymentMethods.ids,
  );
  const isAuthorized = useSelector(accountSelectors.getIsAuthorized);
  const isAccountLoading = useSelector(state => state.account.isLoading);

  const dispatch = useDispatch();

  const loadPaymentMethods = () => {
    dispatch(accountThunks.loadPaymentMethods());
  };

  return {
    isLoading,
    error,
    paymentMethodIds,
    loadPaymentMethods,
    isCreatingPaymentMethod,
    isAuthorized,
    isAccountLoading,
  };
};
