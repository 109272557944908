import { CSSProperties } from "react";

import { Placement } from "./types";

export const getPlacement = (
  placement: Placement,
  offset: number,
): CSSProperties => {
  switch (placement) {
    case "bottom_start":
      return {
        marginTop: offset,
      };

    case "bottom_end":
      return {
        right: 0,
        marginTop: offset,
      };

    case "left_start":
      return {
        top: 0,
        right: "100%",
        marginRight: offset,
      };

    case "left_end":
      return {
        bottom: 0,
        right: "100%",
        marginRight: offset,
      };

    case "right_start":
      return {
        left: "100%",
        top: 0,
        marginLeft: offset,
      };

    case "right_end":
      return {
        left: "100%",
        bottom: 0,
        marginLeft: offset,
      };

    case "top_start":
      return {
        left: 0,
        bottom: "100%",
        marginBottom: offset,
      };

    case "top_end":
      return {
        right: 0,
        bottom: "100%",
        marginBottom: offset,
      };
  }
};
