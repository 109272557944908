/* eslint-disable react-hooks/rules-of-hooks */
import {
  ChangeEvent,
  Fragment,
  useMemo,
  useState,
  ElementType,
  useEffect,
  useRef,
  KeyboardEvent,
  MutableRefObject,
} from "react";
import { Combobox, ComboboxProps } from "@headlessui/react";
import joinClassNames from "classnames";

import ArrowDownIcon from "icons/outline-arrow-down.svg?react";
import CloseIcon from "icons/close.svg?react";
import SearchIcon from "icons/search.svg?react";

import Input from "basics/input";

import ComboboxTrigger from "./components/trigger";
import Scrollbar from "./components/scrollbar";
import Option from "./components/option";

import { OptionType, Placement, BaseProps, ClassNames } from "./duck/types";
import {
  Button as DefaultButton,
  Option as DefaultOption,
} from "./options/default";
import {
  defaultFilter,
  getIsGrouped,
  getProcessedOption,
} from "./duck/selectors";
import { usePlacement } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props<T> extends BaseProps<T> {
  placeholder?: string;

  onFilter?(query: string, item: LabelValue<T>): boolean;
  classNames?: ClassNames & Partial<{ input: string }>;
}

interface SearchSelectFC {
  <
    T,
    TNullable extends boolean | undefined,
    TMultiple extends boolean | undefined,
    TTag extends ElementType,
  >(
    props: Props<T> &
      ComboboxProps<T, TNullable, TMultiple, TTag> & { multiple?: false },
  ): JSX.Element;

  <
    T,
    TNullable extends boolean | undefined,
    TMultiple extends boolean | undefined,
    TTag extends ElementType,
  >(
    props: Props<T> &
      ComboboxProps<Array<T>, TNullable, TMultiple, TTag> & {
        multiple: true;
      },
  ): JSX.Element;
}

const SearchSelect: SearchSelectFC = ({
  options,
  placeholder = "Search for state",
  onFilter = defaultFilter,
  renderButton: ButtonComp = DefaultButton,
  renderOption = DefaultOption,
  placement = "auto",
  size = "sm",
  isLoading = false,
  themeName = "primary",
  isBlock = false,
  className = "",
  classNames = {} as Props<unknown>["classNames"],
  disabled = false,
  value = null,
  ...restProps
}) => {
  const isReadOnly = value && options.length < 2;

  return (
    <Combobox
      {...restProps}
      as="div"
      className={joinClassNames(
        classes.themePresets,
        classes.sizePresets,
        classes.wrapper,
        className,
      )}
      data-type="search-select"
      data-block={isBlock}
      data-size={size}
      data-theme={themeName}
      disabled={disabled || isReadOnly}
      value={value}
    >
      {({ open, value }) => {
        const [query, setQuery] = useState("");
        const closeButtonRef = useRef<HTMLButtonElement>(null);
        const { triggerRef, listStyles } = usePlacement<HTMLButtonElement>(
          placement as Placement,
          [open],
        );
        const renderOptions = useMemo(() => {
          const lowerQuery = query.toLowerCase();

          return options.reduce((acc, item) => {
            if (getIsGrouped(item)) {
              const { options, title } = item;
              const filteredOptions = options.filter(item =>
                onFilter(lowerQuery, getProcessedOption(item)),
              );

              return filteredOptions.length
                ? acc.concat({
                    title,
                    options: filteredOptions,
                  })
                : acc;
            }

            if (onFilter(lowerQuery, getProcessedOption(item))) {
              return acc.concat(item);
            }

            return acc;
          }, [] as OptionType<unknown>[]);
        }, [options, query]);

        const toggleFocus = (event: KeyboardEvent) => {
          if (event.key !== "Tab") {
            return;
          }

          event.preventDefault();

          if (document.activeElement === closeButtonRef.current) {
            triggerRef.current?.focus();
          } else {
            closeButtonRef.current?.focus();
          }
        };

        useEffect(() => {
          setQuery("");

          return () => {
            if (open) {
              triggerRef.current?.focus();
            }
          };
        }, [open]);

        return (
          <>
            {open ? (
              <Combobox.Input
                as={Input}
                className={joinClassNames(
                  classes.inputWrapper,
                  classNames.input,
                )}
                classNames={{ input: classes.input }}
                before={<SearchIcon className={classes.inputIcons} />}
                after={
                  <ComboboxTrigger
                    className={classes.closeTrigger}
                    ref={closeButtonRef}
                    onKeyDown={toggleFocus}
                  >
                    <CloseIcon className={classes.inputIcons} />
                  </ComboboxTrigger>
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setQuery(event.target.value);
                }}
                value={query}
                placeholder={placeholder}
                onKeyDown={toggleFocus}
                ref={triggerRef as MutableRefObject<HTMLInputElement>}
                autoFocus
              />
            ) : (
              <ComboboxTrigger
                className={joinClassNames(
                  classes.selectBlankButton,
                  classes.triggerWrapper,
                  classNames.button,
                )}
                disabled={disabled}
                isReadOnly={isLoading || isReadOnly}
                ref={triggerRef}
              >
                {isLoading ? (
                  <p className={classes.paragraphSkeleton} />
                ) : (
                  <>
                    <ButtonComp selected={value} />
                    {!isReadOnly && (
                      <ArrowDownIcon
                        className={classes.arrowIcon}
                        data-rotated={open}
                      />
                    )}
                  </>
                )}
              </ComboboxTrigger>
            )}
            <Combobox.Options
              style={listStyles}
              as="ul"
              className={joinClassNames(classes.list, classNames.list)}
            >
              {renderOptions.length ? (
                <Scrollbar
                  maxHeight={listStyles.maxHeight}
                  className={classNames.scrollbar}
                >
                  {renderOptions.map(option => {
                    if (getIsGrouped(option)) {
                      return (
                        <Fragment key={option.title}>
                          <li
                            className={joinClassNames(
                              classes.titleOption,
                              classNames.optionTitle,
                            )}
                          >
                            {option.title}
                          </li>
                          {option.options.map(option => {
                            const processed = getProcessedOption(option);

                            return (
                              <Option
                                data={processed}
                                key={processed.label}
                                component={Combobox.Option}
                                renderOption={renderOption}
                              />
                            );
                          })}
                        </Fragment>
                      );
                    }

                    const processed = getProcessedOption(option);

                    return (
                      <Option
                        key={processed.label}
                        component={Combobox.Option}
                        data={processed}
                        renderOption={renderOption}
                      />
                    );
                  })}
                </Scrollbar>
              ) : (
                <div
                  className={joinClassNames(
                    classes.emptyWrapper,
                    classNames.empty,
                  )}
                >
                  No options
                </div>
              )}
            </Combobox.Options>
          </>
        );
      }}
    </Combobox>
  );
};

export default SearchSelect;
