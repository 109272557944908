import CircledIcon from "components/icon";

import { RenderButton } from "../../duck/types";
import { Value } from "./duck/types";

import classes from "./styles/classes.module.scss";

const Button: RenderButton<Value> = ({ selected }) => (
  <div className={classes.wrapper}>
    {selected ? (
      <>
        <CircledIcon className={classes.buttonIcon} src={selected.icon} />
        <p className={classes.text}>{selected.fullName || selected.name}</p>
      </>
    ) : (
      <p className={classes.text}>Choose option</p>
    )}
  </div>
);

export default Button;
