import { FC, useState } from "react";

import { Drawer } from "components/dialog";

import Main from "./screens/main";
import PaymentMethods from "./screens/payment-methods";
import Currency from "./screens/currency";
import Addresses from "./screens/addresses";

import { Screens } from "./duck/types";
import { ProfileDrawerContext } from "./duck/constants";

const SCREENS_MAP: Record<Screens, FC> = {
  main: Main,
  paymentMethods: PaymentMethods,
  currency: Currency,
  addresses: Addresses,
};

interface Props {
  screen: Screens;
}

const ProfileDrawer = ({ screen: initialScreen }: Props) => {
  const [screen, changeScreen] = useState<Screens>(initialScreen);
  const Content = SCREENS_MAP[screen];

  return (
    <Drawer>
      <ProfileDrawerContext.Provider value={{ screen, changeScreen }}>
        <Content />
      </ProfileDrawerContext.Provider>
    </Drawer>
  );
};

export default ProfileDrawer;
