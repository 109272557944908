import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

import { useDialog } from "components/dialog";

import { accountThunks } from "store/account";

import { PartialPaymentMethodInfo } from "../../../duck/types";
import { getSelectedPaymentOptions } from "../../../../duck/selectors";

export const useConnect = (paymentMethodInfo: PartialPaymentMethodInfo) => {
  const { resolve } = useDialog();

  const isCreatingPaymentMethod = useSelector(
    state => state.account.isCreatingPaymentMethod,
  );

  const dispatch = useDispatch();

  const onSavePaymentMethod = () => {
    const newPaymentMethod = {
      currency: paymentMethodInfo.selectedCurrency,
      region: paymentMethodInfo.nestedRegion || paymentMethodInfo.region,
      paymentOptions: getSelectedPaymentOptions(
        paymentMethodInfo.paymentOptions,
      ),
    };

    dispatch(accountThunks.createPaymentMethod(newPaymentMethod))
      .unwrap()
      .then(() => {
        resolve(null);
      });
  };

  return {
    onSavePaymentMethod,
    isCreatingPaymentMethod,
  };
};
