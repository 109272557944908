import useSelector from "hooks/redux/use-selector";
import useDispatch from "hooks/redux/use-dispatch";

import { accountSelectors, accountThunks } from "store/account";
import { currenciesThunks } from "store/currencies";

export const useConnect = () => {
  const currencyIds = useSelector(state => state.currencies.ids);
  const currencyEntities = useSelector(state => state.currencies.entities);
  const isLoadedCurrencies = useSelector(state => state.currencies.isLoaded);
  const currenciesError = useSelector(state => state.currencies.error);
  const currency = useSelector(accountSelectors.selectCurrency);

  const dispatch = useDispatch();

  return {
    isLoadedCurrencies,
    currenciesError,
    currency,
    currencies: currencyIds.map(id => currencyEntities[id]),
    loadCurrencies: () => dispatch(currenciesThunks.loadCurrencies()),
    changeCurrency: (value: CurrencyWithLimits) =>
      dispatch(accountThunks.changeCurrency(value)),
  };
};
