import { FC, PropsWithChildren } from "react";
import joinClassNames from "classnames";

import classes from "./styles/classes.module.scss";

interface Props extends PropsWithChildren {
  className?: string;
}

const Body: FC<Props> = ({ children, className }) => (
  <div className={joinClassNames(classes.wrapper, className)}>
    {children}
  </div>
);

export default Body;
