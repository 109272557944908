import useSelector from "hooks/redux/use-selector";
import useDispatch from "hooks/redux/use-dispatch";

import { useDialog } from "components/dialog";

import { addressesThunks } from "store/addresses";

import { AddressStatuses } from "types/addresses";

export const useConnect = (id: Address["id"]) => {
  const { resolve } = useDialog();
  const dispatch = useDispatch();

  const { address, addressName: initialAddressName } =
    useSelector(state => state.addresses.entities[id]) || {};
  const status = useSelector(state => state.addresses.statuses[id]);

  const onUpdateAddress = (addressName: string) =>
    dispatch(
      addressesThunks.updateAddress({
        addressName,
        address,
        id,
      }),
    )
      .unwrap()
      .then(() => resolve(null));

  const onDeleteAddress = () => {
    dispatch(addressesThunks.removeAddress(id))
      .unwrap()
      .then(() => resolve(null));
  };

  return {
    initialAddressName,
    address,
    onUpdateAddress,
    onDeleteAddress,
    isDeleting: status === AddressStatuses.removing,
  };
};
