import GooglePlayIcon from "icons/google-play.svg?react";
import AppStoreIcon from "icons/app-store.svg?react";

import {
  ANDROID_APPLICATION_URL,
  IOS_APPLICATION_URL,
} from "constants/externalLinks";

import classes from "./style/classes.module.scss";

const Advertisement = () => (
  <div className={classes.wrapper}>
    <p className={classes.text}>Get mobile apps:</p>
    <div className={classes.linksWrapper}>
      <a
        target="_blank"
        href={ANDROID_APPLICATION_URL}
        title="Get it on Google Play"
      >
        <GooglePlayIcon className={classes.storeIcon} />
      </a>
      <a
        target="_blank"
        href={IOS_APPLICATION_URL}
        title="Download on the App Store"
      >
        <AppStoreIcon className={classes.storeIcon} />
      </a>
    </div>
  </div>
);

export default Advertisement;
