import { FC } from "react";
import joinClassNames from "classnames";

import Tick16 from "icons/tick16.svg?react";
import Tick24 from "icons/tick24.svg?react";

import { Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  classNames?: Partial<{ icon: string }>;
  size?: Sizes;
}

const ICONS = {
  sm: Tick16,
  md: Tick24,
};

const Tick: FC<Props> = ({ className, classNames = {}, size = "md" }) => {
  const Icon = ICONS[size];

  return (
    <div
      data-type="tick"
      className={joinClassNames(
        classes.sizePresets,
        classes.wrapper,
        className,
      )}
      data-size={size}
    >
      <Icon className={classNames.icon} />
    </div>
  );
};

export default Tick;
