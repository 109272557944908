import { ChangeEvent, FC, useMemo, useState } from "react";

import Button from "basics/button";

import { Modal } from "components/dialog";
import { RegionIcon } from "components/icon";

import PaymentOptionsChooser from "../components/payment-options-chooser";
import CurrencyChooser from "../components/currency-chooser";
import { useConnect } from "./duck/hooks";
import { LocalGroupedPaymentOption } from "../duck/types";
import { getSelectedPaymentOptions } from "../duck/selectors";

import classes from "./styles/classes.module.scss";

interface Props {
  id: PaymentMethod["id"];
}

const EditPaymentMethodModal: FC<Props> = ({ id }) => {
  const { paymentMethod, saveChanges, isEditing } = useConnect(id);
  const [allPaymentOptions, setAllPaymentOptions] = useState<
    LocalGroupedPaymentOption[]
  >([]);
  const [currencies, setCurrencies] = useState<CurrencyWithDefault[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    paymentMethod.currency,
  );

  const onChangePaymentOptions = (e: ChangeEvent<HTMLInputElement>) => {
    setAllPaymentOptions(prevPaymentMethodInfo =>
      prevPaymentMethodInfo.map(paymentOption => {
        if (paymentOption.category === e.target.name) {
          return {
            ...paymentOption,
            isSelected: e.target.checked,
          };
        }

        return paymentOption;
      }),
    );
  };

  const setInitialPaymentOptions = (
    paymentOptions: LocalGroupedPaymentOption[],
  ) => {
    const selectedCategories = {};
    paymentMethod.paymentOptions.forEach(({ category }) => {
      selectedCategories[category] = true;
    });

    paymentOptions.forEach(paymentOption => {
      if (selectedCategories[paymentOption.category]) {
        paymentOption.isSelected = true;
      }
    });

    setAllPaymentOptions(paymentOptions);
  };

  const selectedPaymentOptions = useMemo(
    () => getSelectedPaymentOptions(allPaymentOptions),
    [allPaymentOptions],
  );

  return (
    <Modal>
      <Modal.Header>Edit payment method</Modal.Header>
      <div className={classes.regionWrapper}>
        <RegionIcon
          size="xl"
          region={paymentMethod.region.parentRegion || paymentMethod.region}
        />
        <p className={classes.regionText}>
          {paymentMethod.region.parentRegion
            ? `${paymentMethod.region.parentRegion.name}, ${paymentMethod.region.name}`
            : paymentMethod.region.name}
        </p>
      </div>
      <h3 className={classes.subtitle}>Preferred payment methods</h3>
      <PaymentOptionsChooser
        regionId={paymentMethod.region.id}
        paymentOptions={allPaymentOptions}
        setInitialOptions={setInitialPaymentOptions}
        onChange={onChangePaymentOptions}
      />
      <h3 className={classes.subtitle}>Currency</h3>
      <CurrencyChooser
        regionId={paymentMethod.region.id}
        currencies={currencies}
        setInitialCurrencies={setCurrencies}
        selectedCurrency={selectedCurrency}
        onChange={setSelectedCurrency}
      />
      <Button
        isBlock
        themeName="primary"
        isLoading={isEditing}
        className={classes.saveButton}
        disabled={
          isEditing || !selectedCurrency || !selectedPaymentOptions.length
        }
        onClick={() => {
          saveChanges({
            ...paymentMethod,
            currency: selectedCurrency,
            paymentOptions: selectedPaymentOptions,
          });
        }}
      >
        Save changes
      </Button>
    </Modal>
  );
};

export default EditPaymentMethodModal;
