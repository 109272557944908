export const enum Steps {
  chooseCountry,
  choosePaymentMethod,
  chooseCurrency,
}

export type LocalGroupedPaymentOption = GroupedPaymentOption & {
  isSelected?: boolean;
};

export interface PaymentMethodInfo {
  paymentOptions: LocalGroupedPaymentOption[];
  currencies: CurrencyWithLimits[];
  selectedCurrency: CurrencyWithLimits | null;
  region: Region;
  nestedRegion: Region;
}

export type PartialPaymentMethodInfo = Partial<PaymentMethodInfo>;

export type SetPaymentMethodInfo = (
  paymentMethodInfo:
    | PartialPaymentMethodInfo
    | ((prevPaymentInfo: PartialPaymentMethodInfo) => PartialPaymentMethodInfo),
) => void;
