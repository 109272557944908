import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { omit } from "lodash";

import referralCodeAPI from "api/referralCode";

import Input from "basics/input";

import { Modal, openAlert, useDialog } from "components/dialog";

import Button from "basics/button";

import globalClasses from "styles/classes.module.scss";

import { FormValues } from "./duck/types";

import classes from "./styles/classes.module.scss";

const ReferralCodeModal = () => {
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormValues>({
    defaultValues: { referralCode: "" },
  });
  const { resolve } = useDialog();

  const submitHandler: SubmitHandler<FormValues> = async ({ referralCode }) => {
    try {
      await referralCodeAPI.redeemReferralCode(referralCode);
      resolve(null);
      openAlert({
        message: "Referral code was successfully submitted",
        type: "success",
      });
    } catch {
      openAlert({
        message: "An error occurred while submitting the referral code",
      });
    }
  };

  return (
    <Modal>
      <Modal.Header classNames={{ title: globalClasses.gradientTitle }}>
        Redeem referral code
      </Modal.Header>
      <h3 className={classes.subtitle}>Get extra points</h3>
      <form className={classes.form} onSubmit={handleSubmit(submitHandler)}>
        <Controller
          control={control}
          name="referralCode"
          render={({ field }) => (
            <Input
              size="md"
              className={classes.input}
              autoFocus
              placeholder="Referral code"
              {...omit(field, "name")}
            />
          )}
        />
        <Button
          disabled={isSubmitting}
          isLoading={isSubmitting}
          className={classes.submitButton}
          themeName="primary"
          type="submit"
          isBlock
        >
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default ReferralCodeModal;
