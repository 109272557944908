import { FC } from "react";

import ProfileGradientIcon from "icons/profile-gradient.svg?react";
import PlusIcon from "icons/plus.svg?react";

import { openAddPaymentMethodModal } from "modules/payment-method-modals";
import { openSignInModal } from "modules/signin-modal";

import Loader from "basics/loader";
import Button from "basics/button";

import ErrorScreen from "components/error";
import EmptyScreen from "components/empty-screen";
import { Drawer } from "components/dialog";

import globalClasses from "styles/classes.module.scss";

import PaymentMethodCard from "./components/payment-method-card";
import { useConnect } from "./duck/hooks";
import { useProfileDrawer } from "../../duck/hooks";

import classes from "./styles/classes.module.scss";

const PaymentMethods: FC = () => {
  const {
    isLoading,
    isCreatingPaymentMethod,
    error,
    paymentMethodIds,
    loadPaymentMethods,
    isAuthorized,
    isAccountLoading,
  } = useConnect();
  const { changeScreen } = useProfileDrawer();

  let content;
  if (!isAuthorized) {
    content = (
      <div className={classes.unauthorizedWrapper}>
        <ProfileGradientIcon />
        <p className={classes.unauthorizedTitle}>Sign in required</p>
        <p className={classes.unauthorizedDescription}>
          Please sign in to manage payment methods
        </p>
      </div>
    );
  } else if (isLoading) {
    content = <Loader className={classes.loader} />;
  } else if (error) {
    content = (
      <ErrorScreen className={classes.errorScreen}>
        <Button
          className={classes.tryAgainButton}
          themeName="primary"
          onClick={() => {
            loadPaymentMethods();
          }}
        >
          Try again
        </Button>
      </ErrorScreen>
    );
  } else if (!paymentMethodIds.length) {
    content = <EmptyScreen />;
  } else {
    content = paymentMethodIds.map(id => (
      <PaymentMethodCard key={id} id={id} />
    ));
  }

  return (
    <>
      <Drawer.Header onBack={() => changeScreen("main")}>
        <h2 className={globalClasses.gradientTitle}>Payment methods</h2>
      </Drawer.Header>
      <Drawer.Body className={classes.drawerContainer}>
        <p className={classes.text}>
          Optimize your experience by sharing your available payment options
        </p>
        <div className={classes.cardsWrapper}>{content}</div>
      </Drawer.Body>
      <Drawer.Footer>
        {isAuthorized ? (
          <Button
            themeName="primary"
            isBlock
            isLoading={isLoading || isCreatingPaymentMethod}
            disabled={isLoading || Boolean(error) || isCreatingPaymentMethod}
            onClick={() => openAddPaymentMethodModal()}
          >
            <PlusIcon className={classes.plusIcon} />
            Add method
          </Button>
        ) : (
          <Button
            themeName="primary"
            disabled={isAccountLoading}
            isBlock
            isLoading={isAccountLoading}
            onClick={() => {
              openSignInModal();
            }}
          >
            Sign in
          </Button>
        )}
      </Drawer.Footer>
    </>
  );
};

export default PaymentMethods;
