import { createContext } from "react";

import PaymentMethodIcon from "icons/payment-method.svg?react";
import WalletIcon from "icons/wallet.svg?react";
import HelpCenterIcon from "icons/help-center.svg?react";
import ChatIcon from "icons/chat.svg?react";

import { Screens, SettingsItem } from "./types";

export const SETTINGS_ITEMS: SettingsItem[] = [
  {
    icon: PaymentMethodIcon,
    text: "Payment methods",
    page: "paymentMethods",
  },
  {
    icon: WalletIcon,
    text: "Addresses",
    page: "addresses",
  },
];

export const SUPPORT_ITEMS = [
  {
    href: "https://coindisco.freshdesk.com/support/home",
    icon: HelpCenterIcon,
    text: "Help Center",
  },
  {
    href: "https://coindisco.freshdesk.com/support/tickets/new",
    icon: ChatIcon,
    text: "Contact Support",
  },
];

export const ProfileDrawerContext = createContext<{
  screen: Screens;
  changeScreen(screen: Screens): void;
}>({
  screen: "main",
  changeScreen: () => null,
});
