import { useMemo } from "react";

import { Placement } from "./types";
import { getPlacement } from "./selectors";

export const usePlacement = (
  placement: Placement = "bottom_start",
  offset: number,
  deps: unknown[],
) => {
  const listStyles = useMemo(
    () => getPlacement(placement, offset),
    ([placement] as unknown[]).concat(deps),
  );

  return {
    listStyles,
  };
};
