import classes from "./styles/classes.module.scss";

const Skeleton = () => (
  <div className={classes.wrapper}>
    <div className={classes.image} />
    <div className={classes.name} />
    <div className={classes.tick} />
  </div>
);

export default Skeleton;
