import { useEffect, useState } from "react";

import servicesAPI from "api/services";

import { getGroupedPaymentOptions } from "operations/payment";

import { LocalGroupedPaymentOption } from "../../../duck/types";

interface Args {
  regionId: Region["id"];
  paymentOptions: LocalGroupedPaymentOption[];
  setInitialOptions: (paymentOptions: LocalGroupedPaymentOption[]) => void;
}

export const usePaymentOptionsForRegion = ({
  regionId,
  paymentOptions,
  setInitialOptions,
}: Args) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadPaymentOptionsForRegion = async () => {
    setIsLoading(true);

    try {
      const paymentOptions = await servicesAPI.fetchPaymentMethodsForRegion(
        regionId,
      );
      setInitialOptions(getGroupedPaymentOptions(paymentOptions));
    } catch (err) {
      setError(err.message);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (paymentOptions?.length) {
      return;
    }

    loadPaymentOptionsForRegion();
  }, []);

  return { isLoading, error, loadPaymentOptionsForRegion };
};
