import { type EntityId } from "@reduxjs/toolkit";

import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

import { accountThunks } from "store/account";

export const useConnect = (id: EntityId) => {
  const paymentMethod = useSelector(
    state => state.account.paymentMethods.entities[id],
  );

  const paymentMethodStatus = useSelector(
    state => state.account.paymentMethodStatuses[id],
  );

  const isDisabledDeleteMethod = useSelector(
    ({
      account: {
        paymentMethods: { entities, ids },
      },
    }) =>
      ids.filter(id => entities[id].isActive).length === 1 &&
      entities[id].isActive,
  );

  const dispatch = useDispatch();

  const removePaymentMethod = (id: PaymentMethod["id"]) => {
    dispatch(accountThunks.removePaymentMethod(id));
  };

  const updatePaymentMethod = (paymentMethod: PaymentMethod) => {
    dispatch(
      accountThunks.updatePaymentMethod({
        paymentMethod,
        isActivityChange: true,
      }),
    );
  };

  return {
    isDisabledDeleteMethod,
    removePaymentMethod,
    updatePaymentMethod,
    paymentMethod,
    paymentMethodStatus,
  };
};
