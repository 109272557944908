import joinClassNames from "classnames";
import { FC, HTMLAttributes } from "react";

import classes from "./styles/classes.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  classNames?: {
    indeterminate?: string;
  };
}

const LineLoader: FC<Props> = ({
  isLoading = true,
  className,
  classNames = {},
  ...props
}) => (
  <div
    data-loading={isLoading}
    className={joinClassNames(classes.lineLoaderWrapper, className)}
    {...props}
  >
    <div
      className={joinClassNames(
        classes.indeterminate,
        classNames.indeterminate,
      )}
    />
  </div>
);

export default LineLoader;
