import { FC } from "react";

import { getDefault } from "selectors/common";

import Button from "basics/button";

import CurrencyChooser from "../../../components/currency-chooser";
import {
  PartialPaymentMethodInfo,
  SetPaymentMethodInfo,
} from "../../duck/types";
import { useConnect } from "./duck/hooks";

import modalClasses from "../../styles/classes.module.scss";

interface Props {
  paymentMethodInfo: PartialPaymentMethodInfo;
  setPaymentMethodInfo: SetPaymentMethodInfo;
}

const ChooseCurrency: FC<Props> = ({
  paymentMethodInfo,
  setPaymentMethodInfo,
}) => {
  const { onSavePaymentMethod, isCreatingPaymentMethod } =
    useConnect(paymentMethodInfo);

  const onChangeCurrency = (currency: CurrencyWithLimits) => {
    setPaymentMethodInfo(prevPaymentInfo => ({
      ...prevPaymentInfo,
      selectedCurrency: currency,
    }));
  };

  const setInitialCurrencies = (currencies: CurrencyWithLimits[]) => {
    setPaymentMethodInfo(prevPaymentInfo => ({
      ...prevPaymentInfo,
      selectedCurrency: getDefault(currencies),
      currencies,
    }));
  };

  return (
    <>
      <h2 className={modalClasses.title}>Choose currency</h2>
      <CurrencyChooser
        regionId={
          paymentMethodInfo.nestedRegion?.id || paymentMethodInfo.region.id
        }
        currencies={paymentMethodInfo.currencies}
        setInitialCurrencies={setInitialCurrencies}
        selectedCurrency={paymentMethodInfo.selectedCurrency}
        onChange={onChangeCurrency}
      />
      <Button
        themeName="primary"
        isLoading={isCreatingPaymentMethod}
        className={modalClasses.nextButton}
        disabled={
          isCreatingPaymentMethod || !paymentMethodInfo.selectedCurrency
        }
        isBlock
        onClick={onSavePaymentMethod}
      >
        Finish
      </Button>
    </>
  );
};

export default ChooseCurrency;
