import { ElementType, Fragment } from "react";
import {
  Menu,
  MenuProps,
  MenuButtonProps,
  MenuItemsProps,
} from "@headlessui/react";
import joinClassNames from "classnames";

import { usePlacement } from "./duck/hooks";
import { Placement } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  children: MenuButtonProps<"button">["children"];
  menuComponent: MenuItemsProps<"div">["children"];
  placement?: Placement;
  offset?: number;
  className?: string;
  classNames?: Partial<{
    menu: string;
  }>;
}

function Dropdown<TTag extends ElementType>({
  children,
  menuComponent,
  placement = "bottom_start",
  offset = 8,
  className,
  classNames = {},
  ...restProps
}: Props & Omit<MenuProps<TTag>, "as">) {
  return (
    <Menu
      as="div"
      className={joinClassNames(classes.wrapper, className)}
      {...restProps}
    >
      {({ open }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { listStyles } = usePlacement(placement, offset, [open]);

        return (
          <>
            <Menu.Button as={Fragment}>{children}</Menu.Button>
            <Menu.Items
              as="ul"
              className={joinClassNames(classes.list, classNames.menu)}
              style={listStyles}
            >
              {menuComponent}
            </Menu.Items>
          </>
        );
      }}
    </Menu>
  );
}

export default Dropdown;
