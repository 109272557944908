import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

import { regionsSelectors, regionsThunks } from "store/regions";

export const useConnect = () => {
  const regions = useSelector(regionsSelectors.selectRegions);
  const isLoaded = useSelector(state => state.regions.isLoaded);
  const regionsError = useSelector(state => state.regions.error);
  const dispatch = useDispatch();

  return {
    regions,
    loadRegions: () => dispatch(regionsThunks.loadRegions()),
    isLoaded,
    regionsError,
  };
};
