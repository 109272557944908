import { useState, useMemo, Fragment } from "react";
import joinClassNames from "classnames";

import Tick24Icon from "icons/tick24.svg?react";

import Button, { BlankButton } from "basics/button";

import ErrorScreen from "components/error";
import Search from "components/search";
import EmptyScreen from "components/empty-screen";
import CircledIcon from "components/icon";
import { Drawer } from "components/dialog";

import globalClasses from "styles/classes.module.scss";

import Skeleton from "./components/skeleton";
import { filterCurrencies } from "./duck/selectors";
import { useConnect } from "./duck/hooks";
import { useProfileDrawer } from "../../duck/hooks";

import profileDrawerClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Currency = () => {
  const {
    currencies,
    currency,
    changeCurrency,
    isLoadedCurrencies,
    currenciesError,
    loadCurrencies,
  } = useConnect();
  const [query, setQuery] = useState("");

  const { changeScreen } = useProfileDrawer();

  const renderCurrencies = useMemo(
    () => filterCurrencies(query.toLowerCase(), currencies || []),
    [currencies, query],
  );

  let content;

  if (currenciesError) {
    content = (
      <ErrorScreen className={classes.errorScreen}>
        <Button
          className={classes.tryAgainButton}
          themeName="primary"
          onClick={() => {
            loadCurrencies();
          }}
        >
          Try again
        </Button>
      </ErrorScreen>
    );
  } else if (!isLoadedCurrencies) {
    content = (
      <ul role="list" className={profileDrawerClasses.list}>
        <Skeleton />
      </ul>
    );
  } else if (!renderCurrencies.length) {
    content = <EmptyScreen />;
  } else {
    content = (
      <ul role="list" className={profileDrawerClasses.list}>
        {renderCurrencies.map(({ title, options }) => (
          <Fragment key={title}>
            <li role="listitem" className={profileDrawerClasses.listTitle}>
              {title}
            </li>
            {options.map(item => (
              <BlankButton
                className={joinClassNames(
                  profileDrawerClasses.listItem,
                  classes.listItem,
                )}
                role="listitem"
                key={item.id}
                onClick={() => changeCurrency(item)}
              >
                <CircledIcon className={classes.circledIcon} src={item.icon} />
                <p className={classes.text}>{item.fullName}</p>
                {currency.name === item.name && (
                  <Tick24Icon className={classes.tick24Icon} />
                )}
              </BlankButton>
            ))}
          </Fragment>
        ))}
      </ul>
    );
  }

  return (
    <>
      <Drawer.Header onBack={() => changeScreen("main")}>
        <h2 className={globalClasses.gradientTitle}>Currency</h2>
        <Search
          className={classes.search}
          name="currency"
          placeholder="Search for currency"
          onSearchChange={setQuery}
        />
      </Drawer.Header>
      <Drawer.Body className={classes.drawerContainer}>{content}</Drawer.Body>
    </>
  );
};

export default Currency;
