import { EntityId } from "@reduxjs/toolkit";

import useSelector from "hooks/redux/use-selector";

export const useConnect = (id: EntityId) => {
  const addressEntity = useSelector(
    state => state.addresses.entities[id],
  );
  const status = useSelector(state => state.addresses.statuses[id]);

  return {
    addressEntity,
    status
  };
};
