import { useContext } from "react";

import useDispatch from "hooks/redux/use-dispatch";
import useSelector from "hooks/redux/use-selector";

import { accountSelectors, accountThunks } from "store/account";
import { getIsAuthorized } from "store/account/duck/selectors";

import { ProfileDrawerContext } from "./constants";

export const useConnect = () => {
  const account = useSelector(accountSelectors.selectAccount);
  const isLoading = useSelector(state => state.account.isLoading);
  const dispatch = useDispatch();
  const currency = useSelector(accountSelectors.selectCurrency);
  const isAuthorized = useSelector(getIsAuthorized);
  const isCurrenciesLoaded = useSelector(state => state.currencies.isLoaded);

  return {
    account,
    logout: () => dispatch(accountThunks.logout()),
    isLoading,
    currency,
    isAuthorized,
    isCurrenciesLoaded,
  };
};

export const useProfileDrawer = () => useContext(ProfileDrawerContext);
