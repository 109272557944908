export const filterCurrencies = (
  query: string,
  currencies: CurrencyWithLimits[],
) => {
  const filtered = query
    ? currencies.filter(
        ({ name, fullName }) =>
          name.toLowerCase().includes(query) ||
          fullName.toLowerCase().includes(query),
      )
    : currencies;
  const popular = [];
  const rest = [];
  for (const item of filtered) {
    if (item.isPopular) {
      popular.push(item);
    } else {
      rest.push(item);
    }
  }

  const result: { title: string; options: CurrencyWithLimits[] }[] = [];
  if (popular.length) {
    result.push({ title: "Popular Currencies", options: popular });
  }

  if (rest.length) {
    result.push({ title: "All Currencies", options: rest });
  }

  return result;
};
