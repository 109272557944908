import { useMemo } from "react";
import joinClassNames from "classnames";

import profileDrawerClasses from "../../../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Skeleton = () => {
  const items = useMemo(
    () =>
      Array.from({ length: 14 }).map((_, index) => (
        <li
          className={joinClassNames(
            profileDrawerClasses.listItem,
            classes.listItem,
          )}
          aria-readonly
          role="listitem"
          key={`${index + 1}`}
        >
          <div className={classes.imageSkeleton} />
          <p className={classes.itemSkeletonTitle} />
        </li>
      )),
    [],
  );

  return (
    <>
      <li role="listitem" className={profileDrawerClasses.listTitle}>
        <p className={classes.skeletonTitle} />
      </li>
      {items.slice(0, 4)}
      <li role="listitem" className={profileDrawerClasses.listTitle}>
        <p className={classes.skeletonTitle} />
      </li>
      {items.slice(4)}
    </>
  );
};

export default Skeleton;
