import useSelector from "hooks/redux/use-selector";
import useDispatch from "hooks/redux/use-dispatch";

import { addressesThunks } from "store/addresses";

export const useConnect = () => {
  const addressIds = useSelector(
    state => state.addresses.ids,
  ) as Address["id"][];
  const addressEntities = useSelector(state => state.addresses.entities);
  const isLoading = useSelector(state => state.addresses.isLoading);
  const error = useSelector(state => state.addresses.error);

  const dispatch = useDispatch();

  return {
    addressIds,
    addressEntities,
    isLoading,
    error,
    loadAddresses: () => dispatch(addressesThunks.loadAddresses()),
  };
};
