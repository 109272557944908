import dialogAPI from "operations/dialog";

import AddPaymentMethodModal from "./AddPaymentMethodModal";

import { Steps } from "./duck/types";

interface OpenModalOptions {
  step: Steps;
}

const openModal = (options?: OpenModalOptions) =>
  dialogAPI.open(<AddPaymentMethodModal initialStep={options?.step} />);

export default openModal;
