import useSelector from "hooks/redux/use-selector";
import useDispatch from "hooks/redux/use-dispatch";

import { PaymentMethodStatuses } from "types/payment";

import { accountThunks } from "store/account";

import { useDialog } from "components/dialog";

export const useConnect = (id: PaymentMethod["id"]) => {
  const { resolve } = useDialog();

  const paymentMethodStatus = useSelector(
    state => state.account.paymentMethodStatuses[id],
  );
  const paymentMethod = useSelector(
    state => state.account.paymentMethods.entities[id],
  );

  const dispatch = useDispatch();

  const saveChanges = (paymentMethod: PaymentMethod) => {
    dispatch(accountThunks.updatePaymentMethod({ paymentMethod }))
      .unwrap()
      .then(() => resolve(null));
  };

  return {
    paymentMethod,
    saveChanges,
    isEditing: paymentMethodStatus === PaymentMethodStatuses.editing,
  };
};
